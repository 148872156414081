@import "~leaflet-loading/src/Control.Loading.css";

body,
html {
  width: 100%;
  height: 100%;
  font-family: '"Helvetica", "Arial", sans-serif';
}

.text-link:link,
.text-link:visited {
  color: #0165a6;
  transition: 250ms;
  text-decoration: none;
}

.text-link:hover,
.text-link:active {
  color: #043d6d;
  text-decoration: underline;
}

:root {
  --amplify-primary-color: #043d6d;
  --amplify-primary-tint: #0165a6;
  --amplify-primary-shade: #3383b7;
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(../public/RobotoMono-Medium.ttf) format('trueType');
}

amplify-container {
  height: calc(100vh - 50px);
}

