[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: rgb(4, 61, 109);
  --amplify-components-button-link-color: #828282;
  --amplify-components-button-link-focus-background-color: #0165A6;
  --amplify-components-button-hover-background-color: #0165A6;
  --amplify-components-button-hover-border-color: var(
    --amplify-components-fieldcontrol-border-color
    );
    --amplify-components-button-primary-hover-background-color: rgba(4, 62, 109, 0.8);
    --amplify-components-button-font-weight: 700;
    --amplify-components-button-loading-color: #fff;
    --amplify-components-button-primary-disabled-color: #fff;
    --amplify-components-button-hover-background-color: #0164a631;

    --amplify-components-button-link-hover-color: rgb(4, 61, 109);
    --amplify-components-button-link-hover-border-color: rgb(4, 61, 109);
    --amplify-components-button-border-color: transparent;
    --amplify-components-button-link-hover-background-color: transparent;
    --amplify-components-button-link-border-width: 1px;
    --amplify-components-button-link-focus-background-color: #0164a631;

}

.amplify-field__show-password {
  border: 1px solid var(--amplify-components-fieldcontrol-border-color);
  border-left: 0px;
}

.data-amplify-authenticator {
  margin: auto 0;
}

#root > div > div.css-s3rs2t > div:nth-child(1)  {
  display: flex;
  justify-content: center;
}

/* "Sign in" button */
#root > div > div > div:nth-child(1) > div > div > div > div > form > div > button {
  width: fit-content;
  margin: 0 0 0 auto;
  padding: 8px 38px;
  background-color: rgb(4, 61, 109);
}

#root > div > div > div:nth-child(1) > div > div > div > div > form > div > button:hover {
  background-color: #0165A6;
}

#root > div > div > div:nth-child(1) > div > div > div > div > form > div > button:disabled {
  background-color: white;
  border: 1px solid rgb(4, 61, 109);
}

/* Push footer to bottom */
#root > div > div > div:nth-child(1) {
  margin: auto;
}

/* Narrow form width */
#root > div > div > div:nth-child(1) > div {
  width: 100%;
  max-width: 400px;
}

/* Narrow form width */
#root > div > div > div:nth-child(1) {
  width: 100%;
}

/* "Send Code" button */
#root
> div
> div
> div:nth-child(1)
> div
> div
> form
> fieldset
> div:nth-child(3)
> button:nth-child(1) {
  width: fit-content;
  margin: 0 0 0 auto;
  padding: 8px 38px;
  font-weight: 600 !important;
}

/* "Reset your password" header */
#root > div > div > div:nth-child(1) > div > div > form > fieldset > h3 {
  color: rgb(21, 41, 57);
  margin: auto 0;
  padding: 32px 0 0;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

#root > div > div > div:nth-child(1) > div > div > form > fieldset > div:nth-child(3) > button:nth-child(2) {
  width: fit-content;
  margin: 0 auto;
  padding: 8px 38px;
}

#root > div > div > div:nth-child(1) > div > div > div > div > form > div > fieldset > div.amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield.password-field > div > div.amplify-field-group__outer-end > button {
  border-color: var(--amplify-components-fieldcontrol-border-color);
}

#amplify-id-\:ro\: {
  -webkit-appearance: none;
  -moz-appearance:textfield;
}
